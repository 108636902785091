import * as React from "react";
import { Icon, type IconProps } from "@linear/orbiter/icons/Icon";

export function UnassignedIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <svg>
        <path d="M14.988 8.647a.473.473 0 0 1-.543.433l-.496-.065a.537.537 0 0 1-.453-.563 5.646 5.646 0 0 0 0-.444.537.537 0 0 1 .453-.563l.496-.065a.473.473 0 0 1 .543.433 7.1 7.1 0 0 1 0 .834Zm-.727-3.551a.473.473 0 0 1-.254.646l-.462.192a.538.538 0 0 1-.674-.261 5.5 5.5 0 0 0-.22-.38.538.538 0 0 1 .11-.715l.396-.305a.473.473 0 0 1 .687.102c.153.231.292.472.417.72Zm-2.406-2.71c.23.152.27.468.102.687l-.305.396a.538.538 0 0 1-.714.11 5.49 5.49 0 0 0-.38-.22.538.538 0 0 1-.261-.674l.191-.462a.473.473 0 0 1 .646-.254c.25.125.49.264.72.417ZM8.416 1.242a.473.473 0 0 1 .433.543l-.065.496a.537.537 0 0 1-.563.453 5.627 5.627 0 0 0-.444 0 .537.537 0 0 1-.563-.453l-.065-.496a.473.473 0 0 1 .433-.543 7.109 7.109 0 0 1 .834 0Zm-3.551.727a.473.473 0 0 1 .646.254l.192.462a.538.538 0 0 1-.261.674c-.13.069-.257.142-.38.22a.537.537 0 0 1-.715-.11l-.305-.396a.473.473 0 0 1 .102-.687c.231-.153.472-.292.72-.417Zm-2.71 2.406a.473.473 0 0 1 .687-.102l.396.305a.537.537 0 0 1 .11.714c-.078.124-.151.25-.22.38a.538.538 0 0 1-.674.262l-.462-.192a.473.473 0 0 1-.254-.646c.125-.25.264-.49.417-.72ZM1.555 7.38a.473.473 0 0 0-.543.433 7.109 7.109 0 0 0 0 .834.473.473 0 0 0 .543.433l.496-.065a.537.537 0 0 0 .453-.563 5.627 5.627 0 0 1 0-.444.537.537 0 0 0-.453-.563l-.496-.065Zm.184 3.984a.473.473 0 0 1 .254-.646l.462-.192a.538.538 0 0 1 .674.261c.069.13.142.257.22.38a.537.537 0 0 1-.11.715l-.396.305a.473.473 0 0 1-.687-.102 6.989 6.989 0 0 1-.417-.72Zm11.418.823a.473.473 0 0 0 .687-.102c.153-.231.292-.472.417-.72a.473.473 0 0 0-.254-.647l-.462-.192a.538.538 0 0 0-.674.261c-.069.13-.142.257-.22.38a.537.537 0 0 0 .11.715l.396.305ZM8 4.23a2 2 0 0 0-2 2v.5a2 2 0 0 0 4 0v-.5a2 2 0 0 0-2-2ZM5.121 11.109a3 3 0 0 1 2.122-.879h1.514a3 3 0 0 1 2.122.879l1.01 1.01c.586.586.592 1.552-.104 2A6.967 6.967 0 0 1 8 15.23a6.967 6.967 0 0 1-3.784-1.11c-.697-.449-.69-1.415-.105-2l1.01-1.011Z" />
      </svg>
    </Icon>
  );
}
